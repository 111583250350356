import React from "react";
import styled from "styled-components";

import meLightSrc from "@assets/me-light.png";
import meLightWebpSrc from "@assets/me-light.webp";
import meDarkSrc from "@assets/me-dark.png";
import meDarkWebpSrc from "@assets/me-dark.webp";

// TODO: Convert and use webp when available.
import headSadSrc from "@assets/emotions/head-sad.png";
import headSadDarkSrc from "@assets/emotions/head-sad-dark.png";
import headHappySrc from "@assets/emotions/head-happy.png";
import headHappyDarkSrc from "@assets/emotions/head-happy-dark.png";
import headVeryHappySrc from "@assets/emotions/head-very-happy.png";
import headVeryHappyDarkSrc from "@assets/emotions/head-very-happy-dark.png";

import ClientOnly from "@components/ClientOnly";
import FadeIn from "@components/FadeIn";
import ImgWithFallback from "@components/ImgWithFallback";

const COLOR_SWAP_TRANSITION_DURATION = 500;

const Me = ({
  colorModeOverride,
  fadeDuration = 600,
  fadeDelay = 200,
  ...delegated
}) => {
  const [colorMode] = React.useState("dark");
  const [mood, setMood] = React.useState("happy");

  let headSrc = null;
  switch (mood) {
    case "happy": {
      headSrc = colorMode === "dark" ? headHappyDarkSrc : headHappySrc;
      break;
    }
    case "very-happy": {
      headSrc = colorMode === "dark" ? headVeryHappyDarkSrc : headVeryHappySrc;
      break;
    }
    case "sad": {
      headSrc = colorMode === "dark" ? headSadDarkSrc : headSadSrc;
      break;
    }
    default: {
      throw new Error("Unrecognized mood: " + mood);
    }
  }

  return (
    <ClientOnly {...delegated}>
      <FadeIn
        duration={fadeDuration}
        delay={fadeDelay}
        onMouseEnter={() => {
          setMood("very-happy");
        }}
        onMouseLeave={() => {
          setMood("happy");
        }}
      >
        <MeLight
          src={meLightWebpSrc}
          fallback={meLightSrc}
          style={{ opacity: colorMode === "light" ? 1 : 0 }}
          alt="3D portrait of the blog's author, Josh Comeau"
        />
        <MeDark
          src={meDarkWebpSrc}
          fallback={meDarkSrc}
          style={{
            opacity: colorMode === "dark" ? 1 : 0,
            transition:
              colorMode === "dark"
                ? "opacity 0ms 0ms"
                : `opacity ${COLOR_SWAP_TRANSITION_DURATION}ms ${COLOR_SWAP_TRANSITION_DURATION}ms`,
          }}
          alt=""
        />

        {headSrc && <Head src={headSrc} alt="" />}
      </FadeIn>
    </ClientOnly>
  );
};

const MeRoot = styled(ImgWithFallback)`
  display: block;
  top: 0;
  left: 0;
  height: 232px;
  pointer-events: none;
`;

const MeLight = styled(MeRoot)`
  position: relative;
  z-index: 2;
  transition: opacity ${COLOR_SWAP_TRANSITION_DURATION}ms;
`;
const MeDark = styled(MeRoot)`
  position: absolute;
  z-index: 1;
`;

const Head = styled.img`
  position: absolute;
  z-index: 3;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

export default Me;
