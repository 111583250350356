import React from "react";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { ArrowUpLeft } from "react-feather";

import { COLORS } from "@constants";
import useMousePosition from "@hooks/use-mouse-position.hook";

import Spinner from "@components/Spinner";
import Spacer from "@components/Spacer";
import SEO from "@components/SEO";
import Me from "@components/Me";
import MaxWidthWrapper from "@components/MaxWidthWrapper";
import { Link } from "gatsby";

const NotFoundPage = () => {
  const [hasMounted, setHasMounted] = React.useState(false);
  const { x, y } = useMousePosition();

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  React.useEffect(() => {
    if (typeof x === "number" && typeof y === "number") {
      document.documentElement.style.setProperty("--cursorX", x + "px");
      document.documentElement.style.setProperty("--cursorY", y + "px");
    }
  }, [x, y]);

  if (!hasMounted) {
    return <Verifying />;
  }

  return (
    <Wrapper>
      <SEO title="404: Not found" />
      <Title>Not Found</Title>
      <Description>Sorry, there is nothing at this URL.</Description>
      <BackLink href="/">
        <BackIcon size="2vw" />
        <Spacer $size={8} />
        Go back home
      </BackLink>

      <MeWrapper>
        <Me colorModeOverride="dark" />
      </MeWrapper>

      <GlobalStyles />
    </Wrapper>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const GlobalStyles = createGlobalStyle`
  body {
    background: ${COLORS.background};
    color: ${COLORS.text};
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACQSURBVHgBhZA9CsQgEIVnxCJHSJkjpFGw8wh7xD2KtRbmCClzhAVRdx6YLsQHyoifP/MxjeSct1KKl3Jl5kXG2Vo7rLUH9hlTSgmAp+cEY0zgGOMupz/0Eq31V0l2mqTW6lXvfaN5VoC/GSXMgqevGQgDSpSEGQhNyjl3Sv0GB7jkewVNMIDmxr8v6TaMi+gPLrNAA0C8lG8AAAAASUVORK5CYII='), auto;
;
  }

  :root {
    cursor: none;
    --cursorX: 0px;
    --cursorY: 0px;
  }
  :root:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    pointer-events: none;
    background: radial-gradient(
      circle 16vmax at var(--cursorX) var(--cursorY),
      rgba(0,0,0,0) 0%,
      rgba(0,0,0,.5) 80%,
      rgba(0,0,0,.8) 100%
    )
  }
`;

const Wrapper = styled(MaxWidthWrapper)`
  max-width: 800px;
  padding-top: 18vh;
  animation: ${fadeIn} 750ms 200ms ease-in both;
`;

const Title = styled.h1`
  font-size: 12vw;
  font-weight: var(--font-weight-bold);

  @media (max-width: 550px) {
    font-size: 64px;
  }
  @media (min-width: 1200px) {
    font-size: 144px;
  }
`;

const Description = styled.p`
  margin-top: 0px;
  font-size: 4vw;
  color: ${COLORS.text};

  @media (max-width: 550px) {
    font-size: 32px;
  }
  @media (min-width: 1200px) {
    font-size: 48px;
  }
`;

const BackLink = styled(Link)`
  display: inline-flex;
  align-items: baseline;
  text-decoration: none;
  font-size: 3vw;
  margin-top: 96px;
  color: ${COLORS.tertiary};

  @media (max-width: 550px) {
    font-size: 24px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
  }
`;

const BackIcon = styled(ArrowUpLeft)`
  transform: translateY(-0.35vw);
`;

const MeWrapper = styled.div`
  position: absolute;
  top: 32vh;
  right: -100px;
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
`;

const Verifying = () => {
  return (
    <VerifyingWrapper>
      <Spinner />
    </VerifyingWrapper>
  );
};

const VerifyingWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-content: center;
`;

export default NotFoundPage;
